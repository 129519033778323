//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment'
import BScroll from '@better-scroll/core'
import Slider from '@better-scroll/slide'
import { format } from 'date-fns/esm/fp'
const MONTH_KEY = 'YYYY-MM'
const DATE_KEY = 'YYYY-MM-DD'
const MODE_MONTH = 0
const MODE_WEEK = 1

BScroll.use(Slider)

export default {
  name: "calendar",
  data() {
    return {
      loading: false,
      weekDays:[
        {name:"一",value:1},
        {name:"二",value:2},
        {name:"三",value:3},
        {name:"四",value:4},
        {name:"五",value:5},
        {name:"六",value:6},
        {name:"日",value:7},
      ],
      monthData: {},
      checkAttendanceData: {},
      style: {width: ''},
      currentMonthKey: moment().format(MONTH_KEY),
      rawDaysAllList: [],
      mode: 1,
      chosenDay: {moment: moment()},
      bs: null,
    }
  },
  props:{
    signList:Array,
  },
  computed: {
    // 渲染数据, 对rawDaysAllList进行了一个按星期分组的操作
    daysShowAll() {
      if (!this.rawDaysAllList.length) return
      let ret = []
      this.rawDaysAllList.forEach(item => {
        let obj = {
          monthKey: item.monthKey,
          daysAll: []
        }
    
        if(this.chosenDay.moment.format(MONTH_KEY) == item.monthKey){
          item.daysAll.map(day=>{
            if(day.moment.isBefore(moment().startOf('day'))){
              day.stateLight = 'YELLOW'
            }else{
              day.stateLight = 'GREY'
            }
            this.signList.map(v=>{
              if(day.moment.isSame(moment(v.signTime,'yyyy-MM-DD'))){
                if(v.isHoliday){
                  day.stateLight = 'GREY'
                }else{
                  day.stateLight = 'BLUE'
                }
                
              }
            })
          })
        }
        for (let i = 0; i < 6; i++) {
          if (!item.daysAll.slice(7 * i, 7 * (i + 1).length)) break
          obj.daysAll.push({
            key: `floor-${i}`,
            list: item.daysAll.slice(7 * i, 7 * (i + 1))
          })
        }
        ret.push(obj)
      })
      ret.sort((a, b) => {
        return moment(a.monthKey).valueOf() - moment(b.monthKey).valueOf()
      })
      
      console.log(ret,this.signList);
      return ret
    },
    currentPage() {
      if (this.daysShowAll) {
        return this.daysShowAll.findIndex(item => item.monthKey === this.currentMonthKey)
      }
    },
    currentDaysShowMonth() {
      return this.daysShowAll ? this.daysShowAll.find(item => item.monthKey === this.currentMonthKey) : null
    },
    // 动态设置DOM高度, 因为每个月的星期数不一致, 日历区域的高度也不统一
    expandWrapperStyle() {
      if (this.currentDaysShowMonth && this.mode === MODE_MONTH) {
        let weekNum = this.currentDaysShowMonth.daysAll.reduce((total, item) => {
          return item.list.length ? ++total : total
        }, 0)

        return {
          top: `${-46 * (6 - weekNum)}px`
        }
      }
    },
    // 同上
    indexWrapperStyle() {
      if (this.currentDaysShowMonth && this.mode === MODE_MONTH) {
        let weekNum = this.currentDaysShowMonth.daysAll.reduce((total, item) => {
          return item.list.length ? ++total : total
        }, 0)
        return {
          height: `${308 - 46 * (6 - weekNum)}px`
        }
      }
    }
  },
  watch: {
    storeId: {
      immediate: true,
      handler(newVal,oldVal){
        if(!oldVal){

          // this.initMonthData()
        }
      }
    },
    chosenDay: {
      immediate: true,
      handler(newDay) {
        this.initAttendanceDetail(newDay)
      }
    }
  },
  filters: {
    momentFilter(n) {
      let dayShow = n.format('DD')
      if (dayShow.startsWith('0')) {
        return dayShow.substring(1)
      } else {
        return dayShow
      }
    }
  },
  async mounted() {
    // 生成 月份-该月份下所有日期数据的字典:monthData {'2021-07': [{moment: xx, state: xx},.......], ...}, 可理解为数据仓库
    await this.initMonthData()
    // 生成 辅助展示日历的数组 rawDaysAllList,
    // 因为展示日历数据时, 还可能包含上个月/下个月的数据, 所以monthData数据不能直接用来渲染。 不过rawDaysAllList也不能直接用来渲染, 需要做一个按星期划分的操作, daysShowAll是最终型。
    await this.generateRawDays()
    // 动态设置slider区域的宽度, 因为用到滚动插件, 必须设置一个静态的宽度值
    this.initStyle()
    this.initBetterScroll()
    // 通知父组件取消loading以及执行一些需要待calendar加载后的操作
    this.$emit('done')
  },
  methods: {
    async initMonthData(monthKey) {
      if (this.monthData[monthKey]) return
      let list = []
      if (!monthKey) {
        let currentMonthKey = moment().format(MONTH_KEY)
        let previousMonthKey = moment().subtract(1, 'month').format(MONTH_KEY)
        let previous2MonthKey = moment().subtract(2, 'month').format(MONTH_KEY)
        let nextMonthKey = moment().add(1, 'month').format(MONTH_KEY)
        let next2MonthKey = moment().add(2, 'month').format(MONTH_KEY)
        list.push(currentMonthKey, previousMonthKey, previous2MonthKey, nextMonthKey, next2MonthKey)
      } else {
        let targetMonthKey = moment(monthKey).format(MONTH_KEY)
        list.push(targetMonthKey)
      }
      let checkAttendanceData = await this.getSignedData(list)
      this.checkAttendanceData = Object.assign({}, this.checkAttendanceData, checkAttendanceData)
      for (let i = 0; i < list.length; i++) {
        let item = list[i]
        // 生成键值对
        this.monthData[item] = pushAllDateByMonth.call(this, item)
      }
      console.log(123);
      function pushAllDateByMonth(monthKey) {
        let ret = []
        // [{moment: xx, state: xx},.......]
        let daysInMonth = moment(monthKey).daysInMonth()
        let monthStart = moment(monthKey).startOf('month').valueOf()
        for (let i = 0; i < daysInMonth; i++) {
          const dayMoment = moment(monthStart + i * 86400 * 1000)
          if(dayMoment.isBefore(moment().startOf('day'))){
            ret.push({
              moment: dayMoment,
              stateLight:'YELLOW'
            })
          }else{
            ret.push({
              moment: dayMoment,
              stateLight:'GREY'
            })
          }
          
          // const existItem = ret.find(item => {
          //   if (item.DATUM) {
          //     return moment(item.DATUM).format(DATE_KEY) === dayMoment.format(DATE_KEY)
          //   }
          // })

          // if (existItem) {
          //   existItem.moment = moment(existItem.DATUM)
          //   existItem.state = existItem.STATE
          //   existItem.stateLight = existItem.STATELIGHT
          // } else {
          //   ret.push({
          //     moment: dayMoment,
          //   })
          // }
        }
        ret.sort((a,b) => a.moment.valueOf() - b.moment.valueOf())
        return ret
      }

    },

    getMonthData(monthKey) {
      let ret = []
      let daysInMonth = moment(monthKey).daysInMonth()
      let monthStart = moment(monthKey).startOf('month').valueOf()
      for (let i = 0; i < daysInMonth; i++) {
        const dayMoment = moment(monthStart + i * 86400 * 1000)
        ret.push({
          moment: dayMoment,
        })
      }
      return ret
    },

    // 获得SAP系统中记录的考勤数据
    async getSignedData(list) {
      let asyncTaskList = []
      let checkAttendanceData = {}
      console.log('getSignedData',list,this.signList);
      // return
      // 减少网络性能开销, 直接生成所有异步任务, 一次性完成所有请求
      // for (let i = 0; i < list.length; i++) {
      //   let task = this.axios
      //   .post(api.getStoreFigureSignList, {
      //   queryMapObject: {
      //     storeId:'E001Z003',
      //     startTime:moment(list[i],'yyyy-MM').startOf('month').format("yyyy-MM-DD") + ' 00:00:00',
      //     endTime:moment(list[i],'yyyy-MM').endOf('month').format("yyyy-MM-DD") + ' 23:59:59',
      //   }
      // })
      //   asyncTaskList.push(task)
      // }

      // let data = await Promise.all(asyncTaskList)
      // console.log(data);
      // data.forEach((item, index) => {
      //   let key = list[index]
      //   checkAttendanceData[key] = item
      // })

      return checkAttendanceData
    },

    // 生成rawDaysAllList
    async generateRawDays() {
      let keys = Object.keys(this.monthData)
      for (let i = 0; i < keys.length; i++) {
        let monthKey = keys[i]
        // 如果已生成, 则跳过
        if (this.rawDaysAllList.find(item => item.monthKey === monthKey)) continue

        // 1. 因为当月日历必定展示当月所有天数, 所以先将当月所有天数push进当月中
        let dayListInMonth = this.monthData[monthKey]
        let rawDaysData = {
          monthKey: monthKey,
          daysAll: []
        }
        rawDaysData.daysAll.push(...dayListInMonth)

        // 2. 根据当月一号和最后一号各是星期几, 计算需要向上个月或者下个月借取的天数

        // 获得当前月开始日的星期数
        let firstDay = moment(monthKey).startOf('month').day()
        // 获得当前月结束日的星期数
        let endDay = moment(monthKey).endOf('month').day()
        // 通过以下这两个字典判断需要向上个月或者下个月借取的天数
        const startCompletionMap = {1: 0, 2: 1, 3: 2, 4: 3, 5: 4, 6: 5, 0: 6}
        const endCompletionMap = {1: 6, 2: 5, 3: 4, 4: 3, 5: 2, 6: 1, 0: 0}

        // 3. 执行借取天数的具体逻辑

        // 如果当月一号为周一, 就不需要借取
        if (firstDay !== 1) {
          let targetPreviousMonth = moment(monthKey).subtract(1, 'month').format(MONTH_KEY)
          rawDaysData.daysAll.unshift(...this.getMonthData(targetPreviousMonth).slice(startCompletionMap[firstDay] * -1))
        }
        // 如果当月最后一天是周日, 就不需要借取
        if (endDay !== 0) {
          let targetNextMonth = moment(monthKey).add(1, 'month').format(MONTH_KEY)
          rawDaysData.daysAll.push(...this.getMonthData(targetNextMonth).slice(0, endCompletionMap[endDay]))
        }
        this.rawDaysAllList.push(rawDaysData)
      }
    },

    // 跳转到具体日期
    jumpToDate(date) {
      let momentDate = moment(date)
      let jumpMonth = momentDate.format(MONTH_KEY)
      // 需要跳转到的daysShowAll的下标
      let index = this.daysShowAll.findIndex(item => item.monthKey === jumpMonth)
      if (index < 0) return
      let item = this.rawDaysAllList.find(item => item.monthKey === jumpMonth)
      // 需要高亮的在daysShowAll中的日期
      let targetDay = item.daysAll.find(item => item.moment.format(DATE_KEY) === momentDate.format(DATE_KEY))

      let refName = 'calendar-page-' + index
      // 需要跳转到的dom
      let ref = this.$refs[refName][0]

      this.$nextTick(() => {
        this.bs.scrollToElement(ref, .5 * 1000)
        this.mode = MODE_WEEK
        // 模拟点击
        this.onDateClick(targetDay)
        // _isClickHandleSlider 标志位
        // 当切换月份时, 高亮日期默认会重置为展示的月份的第一天
        // 但是一些特殊场景不需要此行为, 因此需要_isClickHandleSlider作为标志位提示
        // 1. 手动点击非本月的日期进行展示月份的切换
        // 2. 执行jumpToDate跳转函数
        this._isClickHandleSlider = true
      })
    },

    initStyle() {
      this.style.width = this.$refs['calendar-wrapper'].clientWidth + 'px'
    },

    initBetterScroll() {
      this.$nextTick(() => {
        // https://better-scroll.github.io/docs/zh-CN/guide/base-scroll-options.html
        this.bs = new BScroll(this.$refs['calendar-wrapper'], {
          scrollY: false,
          scrollX: true,
          momentum: false,
          click: true,
          eventPassthrough: 'vertical',
          // https://better-scroll.github.io/docs/zh-CN/plugins/slide.html
          slide: {
            loop: false,
            autoplay: false,
            startPageXIndex: this.currentPage,
          }
        })
        this.bs.goToPage(this.currentPage, 0)
        this.bs.on('slidePageChanged', async (page) => {

          this.loading = true
          this.bs.disable()


          this.currentMonthKey = this.daysShowAll[page.pageX].monthKey
          // 如果当前是点击该页中的不属于该月的日期进行翻页跳转的话, 重置标志位
          if (this._isClickHandleSlider) {
            this._isClickHandleSlider = false
          } else {
            // 将默认选中日期重置为当前月的第一天
            this.chosenDay = {moment: moment(this.currentMonthKey).startOf('month')}
          }
          this.$emit('monthChange', this.currentMonthKey)


          // 如果当前是倒数最后一月或者是倒数最后两月时, 提前去initMonthData获取数据
          if (page.pageX === (this.daysShowAll.length - 1)) {
            let nextMonthKey = moment((this.daysShowAll[this.daysShowAll.length - 1]).monthKey).add(1, 'month').format(MONTH_KEY)

            await this.initMonthData(nextMonthKey)

            await this.generateRawDays()

            this.$nextTick(() => {
              this.bs.refresh()
            })
          }

          // 如果当前滚动到第一页了, 那么要去获取数据了
          if (page.pageX === 0) {
            let prevMonthKey = moment((this.daysShowAll[0]).monthKey).subtract(1, 'month').format(MONTH_KEY)

            await this.initMonthData(prevMonthKey)

            await this.generateRawDays()
            this.bs.goToPage(1, 0, 0)
            this.$nextTick(() => {
              this.bs.refresh()
            })
          }


          this.loading = false
          this.bs.enable()
        })
      })
    },

    onDateClick(day, week, month) {
      // 如果当前日期不在当前月中, 则要进行翻页
      if (month && this.dayNotInMonth(day, month)) {
        let dd = day.moment.format('DD') | 0
        // 20不严谨, 但不影响功能
        dd > 20 ? this.bs.prev() : this.bs.next()
        this._isClickHandleSlider = true
      }
      this.chosenDay = day
    },

    initAttendanceDetail(day) {
      let res = {}
      res.STATE = day.STATE
      res.date = day.moment.format(DATE_KEY)
      this.$emit('changeChosenAttendanceDetail', res)
    },

    toggleMode() {
      this.$nextTick(() => {
        this.mode ? this.mode = MODE_MONTH : this.mode = MODE_WEEK
      })
    },

    weekShow(week, monthIndex) {
      if (this.mode === MODE_MONTH) {
        return true
      }
      if (monthIndex !== this.currentPage) {
        return week.key === 'floor-0';
      }
      return week.list.some(item => {
        return item.moment.format(DATE_KEY) === this.chosenDay.moment.format(DATE_KEY)
      })
    },

    dayNotInMonth(day, month) {
      return day.moment.format(MONTH_KEY) !== month.monthKey
    },

    isChosenDay(day) {
      return day.moment.format(DATE_KEY) === this.chosenDay.moment.format(DATE_KEY)
    },

    isToday(day) {
      return day.moment.format(DATE_KEY) === new moment().format(DATE_KEY)
    },

    isNormalClock(day) {
      return day.stateLight === 'BLUE' && day.moment.isBefore(moment().endOf('day'))
    },
    dayInWeek(weekday){
      return this.mode == MODE_WEEK && weekday.value === this.chosenDay.moment.weekday()
    },

  }
}
